import { useEffect, useState } from "react";
import myapplication from "../../../assets/Images/appLetterimage.png";
import "./ApplicationLetter.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import moment from "moment";

const ApplicationLetter = () => {
  const [data, setUserData] = useState(null);
  const navigate = useNavigate();
  const [cookies] = useCookies(["user"]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://sailorswaveadmins-backend.onrender.com/candidates`
      );
      const user = response.data.find(
        (usernumber) =>
          parseInt(usernumber.mobileNumber) === parseInt(cookies.user)
      );
      setUserData(user || null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center fs-3 text-primary">Loading...</div>;
  }

  if (error) {
    return <div className="text-center fs-3 text-danger">{error}</div>;
  }

  if (!data) {
    return (
      <div className="text-center fs-3 text-warning">
        User Application is not created.
      </div>
    );
  }

  return (
    <section>
      <div className="application-container">
        <div className="header pb-3">
          <img
            src={myapplication}
            alt="Application Icon"
            className="header-icon"
          />
          <span className="header-title">Application Letters</span>
        </div>
        <div>
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Application No.</th>
                <th>Submitted Application</th>
                <th>Application Status</th>
                <th>Admit Card Status</th>
                <th>Download Admit Card</th>
                <th>Interview Date</th>
                <th>Selection Letter</th>
                <th>Confirmation Letter</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{data.applicationId}</td>
                <td>{moment(data.updatedAt).format("YYYY-MM-DD")}</td>
                <td>{data.applicationstatus?.status || "Not Available"}</td>
                <td>{data.admitcard?.status || "Pending"}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      if (data.admitcard.date) {
                        navigate('/interviewletterhead3');
                      } else {
                        navigate('/applicationletter')
                      }
                    }}
                  >
                    Download
                  </button>
                </td>
                <td>{data.admitcard?.date || "Not Checked"}</td>
                <td>{data.selectionletter?.status || "Not Available"}</td>
                <td>{data.confirmationletter?.status || "Not Available"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ApplicationLetter;
